/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "./Dialog/Dialog";
import { FaTiktok } from "react-icons/fa";

import { axiosData } from "../Config/Axios";
import { toast } from "react-toastify";

function Footer() {
  const navigate = useNavigate();

  const handleCityClick = (cityName, cheap) => {
    const Data = new FormData();
    const params = new URLSearchParams();

    Data.append("byCity", cityName);
    params.append("byCity", cityName);
    if (cheap === "cheap") {
      // Data.append("byDistrict", "Accra");
      Data.append("byMin", 100);
      Data.append("byMax", 600);
    }
    axiosData
      .post("/listing-search", Data)
      .then((response) => {
        console.log("Form submitted successfully!", response);

        if (response.data) {
          const queryString = params.toString();
          const url = queryString
            ? `/en-gh/listings?${queryString}`
            : "/listings";

          navigate(url, {
            state: response?.data?.listings,
          });

          toast.success(response?.data?.message);
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        navigate("/en-gh/listings", {
          state: [],
        });

        // toast.error(error?.message);
      });
  };
  const curentYear = new Date().getFullYear();

  return (
    <>
      <footer
        id="footer"
        className="footer footer-1 "
        style={{ position: "relative", top: "21px" }}
      >
        <div className="footer-widget">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-3 widget--about">
                <div className="widget--content">
                  <div className="footer--logo">
                    <img
                      src="/assets/images/rent-logo-light.png"
                      alt="logo"
                      style={{ height: 40 }}
                    />
                  </div>
                  <p className="nb">
                    RentisReady.com is a leading online platform dedicated to
                    simplifying the rental process for both landlords and
                    tenants. Specializing in residential properties,
                    RentisReady.com offers a comprehensive solution for renting
                    rooms, houses, and apartments.
                  </p>
                  <div className="footer--contact">
                    <ul className="list-unstyled mb-0">
                      <li>
                        <i className="fa fa-map-marker" />:
                        <a
                          style={{ color: "white" }}
                          href="https://maps.app.goo.gl/dD7ykdc23BMLdM2j7"
                          target="_blank"
                        >
                          Near Togorme DA School
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-phone" />:
                        <a style={{ color: "white" }} href="tel:+233244622816">
                          +233 244 622 816
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-whatsapp" />:
                        <a
                          style={{ color: "white" }}
                          href="https://api.whatsapp.com/send?phone=233244622816"
                          target="_blank"
                        >
                          +233 244 622 816
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-envelope" />:
                        <a
                          style={{ color: "white" }}
                          href="https://company.rentisready.com/s-email.php"
                        >
                          info@rentisready.com
                        </a>
                      </li>
                    </ul>
                    <div className="row footer--bar">
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="social-icons">
                          <a href="https://company.rentisready.com/s-facebook.php">
                            <i className="fa fa-facebook" />
                          </a>
                          <a href="https://company.rentisready.com/s-instagram.php">
                            <i className="fa fa-instagram" />
                          </a>
                          <a href="https://company.rentisready.com/s-youtube.php">
                            <i className="fa fa-youtube" />
                          </a>
                          <a href="https://company.rentisready.com/s-tiktok.php">
                            <FaTiktok />
                          </a>
                          <a href="https://company.rentisready.com/s-twitter.php">
                            <i className="fa fa-twitter" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-sm-3 col-md-2 col-md-offset-1 widget--links">
                <div className="widget--title">
                  <h5>Useful Links</h5>
                </div>
                <div className="widget--content">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <Link to={"/en-gh"}>Home</Link>
                    </li>
                    <li>
                      <a
                        href="https://company.rentisready.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        About us
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.rentisready.com/careers.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Careers
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.rentisready.com/blog.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.rentisready.com/guidelines.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Rental Guides
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.rentisready.com/store.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        RentisReady Store
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.rentisready.com/contact.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Our Contact
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.rentisready.com/report.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Report an Issue
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.rentisready.com/faqs.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        FAQs
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.rentisready.com/terms.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Use
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.rentisready.com/privacy.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.rentisready.com/advertise.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Advertise with us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xs-12 col-sm-3 col-md-2 widget--links">
                <div className="widget--title">
                  <h5>Major City Rentals</h5>
                </div>
                <div className="widget--content">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <a href="#" onClick={() => handleCityClick("Accra")}>
                        Accra
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Bolgatanga")}>
                        Bolgatanga
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Cape Coast")}>
                        Cape Coast
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Ho")}>
                        Ho
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Koforidua")}>
                        Koforidua
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Kumasi")}>
                        Kumasi
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Sunyani")}>
                        Sunyani
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Takoradi")}>
                        Takoradi
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Tamale")}>
                        Tamale
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Techiman")}>
                        Techiman
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Tema")}>
                        Tema
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Wa")}>
                        Wa
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xs-12 col-sm-3 col-md-2 widget--links">
                <div className="widget--title">
                  <h5>Cheap Accra Rentals</h5>
                </div>
                <div className="widget--content">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <a
                        href="#"
                        onClick={() => handleCityClick("Abelemkpe", "cheap")}
                      >
                        Abelemkpe
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => handleCityClick("Ablekuma", "cheap")}
                      >
                        Ablekuma
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => handleCityClick("Achimota", "cheap")}
                      >
                        Achimota
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => handleCityClick("Adenta", "cheap")}
                      >
                        Adenta
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => handleCityClick("Ashaiman", "cheap")}
                      >
                        Ashaiman
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => handleCityClick("Dansoman", "cheap")}
                      >
                        Dansoman
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => handleCityClick("Lapaz", "cheap")}
                      >
                        Lapaz
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => handleCityClick("Madina", "cheap")}
                      >
                        Madina
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => handleCityClick("Ofankor", "cheap")}
                      >
                        Ofankor
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => handleCityClick("Oyibi", "cheap")}
                      >
                        Oyibi
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => handleCityClick("Pokuase", "cheap")}
                      >
                        Pokuase
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => handleCityClick("Tema", "cheap")}
                      >
                        Tema
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer--copyright text-center">
          <div className="container">
            <div className="row footer--bar">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <a href="https://lispira.com" target="_blank" className="text-white">
                  © {curentYear} Lispira Corporation (DBA CarsareReady.com). All
                  Rights Reserved.
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <Dialog id={"signupModules"} />
    </>
  );
}

export default Footer;
